import * as React from "react"
import img1 from "../images/french_manicure.png"
import img2 from "../images/lash_lift.png"
import img3 from "../images/Julita_Kowalska_Wasilewski_elipse.png"
import Seo from "../components/seo"
// import PropTypes from "prop-types"

const AboutUs = () => {
  return (
    <div className="about-section" id="about">
      <h1>About us</h1>
      <p className="p1">
        <img className="img1" src={img1} alt="" />
        Hello, my name is Julita Kowalska Wasilewski. Welcome to my page. I’m so
        excited to tell you a little about myself to help you make your decision
        on a beauty professional that will best suit your needs. I have been an
        Esthetician and Nail Technician for over 10 years. My passion lies in
        Eyelash Extensions. From the start of my career I have always taken
        pride in professionalism. I like to approach each client individually
        and go the extra mile to make every effort to ensure everyone who
        receives a service from me, knows how important they are. I like to
        continue my education and attend additional courses to gain knowledge,
        so that I am up to date on the latest trends to provide to my clients.
        My job is really rewarding; I absolutely love helping my clients feel
        their best, it’s truly fulfilling. If I had to describe what I do in one
        word it would be “Art”. That’s how I came up with the name “European
        Beauty Atelier”. An atelier is French for the word ‘room’ where an
        artist creates their work.
      </p>
      <p className="p2">
        <img className="img2" src={img2} alt="" />
        My atelier is located in the heart of Ogden. I really wanted to create a
        unique business and felt I could do so by offering treatments that are
        unlike those offered locally. I’m from Europe, so I created a place
        where I get to combine what I’ve learned from both American and European
        techniques and standards. The services and treatments that I offer are
        aimed for clients who would like to enhance their natural beauty while
        using natural effects. The services I provide are done meticulously and
        above all, safely. I pride myself in paying attention to health and
        safety requirements. Treatments and services are to only be done with
        professional products, disposable materials, and the treatment tools are
        sterilized in the highest class B type autoclave. I enjoy what I do
        everyday and thank every client for supporting me on my journey. Your
        smile and positive feedback is the best reward. I invite you to book a
        service through my booking link and hope to see you as a future client
        and get to know you.
      </p>
      <p className="p3">
        Best Wishes,
        <br />
        Julita Kowalska Wasilewski
        <br />
        European Beauty Atelier
        <img className="img3" src={img3} alt="" />
      </p>
    </div>
  )
}

// AboutUs.propTypes = {
//   siteTitle: PropTypes.string,
// }

// AboutUs.defaultProps = {
//   siteTitle: `About us`,
// }

export const Head = () => <Seo title="About us" />

export default AboutUs
